.navigation

  &--trigger
    @apply w-12 h-12 md:w-16 md:h-16
    @apply text-green hover:text-green-dark transition-colors
    @apply z-[100] border-0 bg-none p-0 outline-none

    &-deco-01,
    &-deco-02,
    &-deco-03,
    &-deco-04
      @apply content-['']
      @apply block w-5 md:w-7 h-0.5 md:h-[3px]
      @apply absolute top-1/2 left-1/2 -translate-x-1/2
      @apply transition-all duration-200 origin-center
      @apply bg-current

    &-deco-01
      @apply rotate-0
      @apply -translate-y-1.5 md:-translate-y-[0.45rem]
    &-deco-02
      @apply rotate-0
      @apply translate-y-1.5 md:translate-y-[0.45rem]
    &-deco-03
      @apply rotate-0
    &-deco-04
      @apply rotate-0

    .navigation__active &
      &-deco-01
        @apply opacity-0 -translate-y-4
      &-deco-02
        @apply opacity-0 translate-y-4
      &-deco-03
        @apply rotate-45
      &-deco-04
        @apply -rotate-45


  &--header
    @apply opacity-0 invisible
    // @apply lg:opacity-100 lg:visible
    .navigation__active &
      @apply opacity-100 visible

    .navigation--link
      @apply hover:text-green transition-colors
    .navigation--link.is-active
      @apply text-green hover:text-green

  &--header-item
    @apply opacity-100 translate-y-0
    transition: opacity 0.5s
    .fade-out &
      @apply opacity-0
