.header
  .base--header
    @apply py-2 md:py-3
    // @apply pb-2 md:pb-3 lg:pb-4
    @apply transition-all duration-300

  &-logo
    @apply relative z-[100]
    @apply w-24 md:w-52 lg:w-56
    // @apply p-2 md:p-4 lg:p-6
    // @apply bg-white rounded-md md:rounded-lg lg:rounded-xl
    // @apply shadow-md md:shadow-lg
    // @apply -mb-12 md:-mb-20 lg:-mb-20
    @apply transition-all duration-300

  // &--navigation-wrapper
  //   @apply lg:pb-5

  &.has-scrolled
    // .base--header
    //   @apply py-1 lg:py-2
    .header-logo
      @apply w-24 md:w-32 lg:w-36
      // @apply p-1 md:p-2 lg:p-3
      // @apply shadow-none
      // @apply -mb-0 md:-mb-0 lg:-mb-0


