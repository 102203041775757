// .tns-controls button
//   @apply absolute z-20
//   @apply top-2
//   @apply md:top-1/2 md:-translate-y-1/2
//   @apply w-10 h-10
//   @apply overflow-hidden
//   text-indent: -1111rem

//   &[data-controls="prev"]
//     @apply left-1/2 -translate-x-10 md:translate-x-0 md:left-4 xl:left-4
//     background: url("/assets/images/arrow-slider-prev.svg") no-repeat
//     background-position: 50% 50%
//     background-size: 14px 14px
//     @screen md
//       background-size: 16px 16px
//   &[data-controls="next"]
//     @apply right-1/2 translate-x-10 md:translate-x-0 md:right-4 xl:right-4
//     background: url("/assets/images/arrow-slider-next.svg") no-repeat
//     background-position: 50% 50%
//     background-size: 14px 14px
//     @screen md
//       background-size: 16px 16px


.tns-nav
  @apply absolute -bottom-8 md:-bottom-10 h-4 w-full
  @apply flex justify-center gap-4

  button
    @apply h-2 w-2 md:h-2.5 md:w-2.5 rounded-full bg-green opacity-20 hover:opacity-100 transition-opacity
    &.tns-nav-active
      @apply opacity-100
