.editor

  @apply text-zinc-800
  @apply text-sm md:text-base

  h1,
  .h1
    @apply font-headline uppercase tracking-wide
    @apply text-2xl md:text-5xl text-center
    @apply block mb-4 md:mb-6
    @apply text-green


  h2,
  .h2
    @apply font-headline uppercase tracking-wide
    @apply text-xl md:text-3xl
    @apply block mb-2 md:mb-4
    @apply text-green

  h3,
  h4,
  .h3,
  .h4
    @apply font-headline uppercase tracking-wide
    @apply text-lg md:text-xl text-zinc-800
    @apply block mb-3 md:mb-4




  p ~ h1,
  p ~ h2,
  p ~ h3,
  p ~ h4
    @apply mt-12

  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  a
    @apply text-green hover:text-green-dark transition-colors

  ol,
  ul
    @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-3 pl-6 relative
      &:before
        content: '• '
        @apply absolute left-0 text-green
