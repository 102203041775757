.ac-header
  @apply relative z-0

  .accordion-trigger-icon
    @apply absolute top-1/2 -translate-y-1/2 right-4 md:right-6 rotate-0 transition-transform

  .is-active &
    .accordion-trigger-icon
      @apply -rotate-180

  &:focus,
  &::-moz-focus-inner
    border: none
    outline: none !important

// .ac-header
//   .is-active &
//     @apply border-b-transparent

.ac-text
  // @apply px-6 lg:px-12
  @apply pt-3 lg:pt-4
  @apply pb-4 lg:pb-6

// .ac.is-active + .ac
//   .ac-header

