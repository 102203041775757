@import 'fonts';
// @import 'properties';

@import 'tailwindcss/base';
@import 'custom-base.scss';

@import 'tailwindcss/components';
@import 'custom-components.scss';

@import 'tailwindcss/utilities';
@import 'custom-utilities.scss';

@import 'accordion';
@import 'base';
@import 'editor';
@import 'embed';
@import 'footer';
@import 'header';
@import 'hero';
@import 'img';
// @import 'linkteaser';
@import 'navigation';
@import 'reveal';
@import 'slider';

@import "./vendor/tiny-slider.css"
