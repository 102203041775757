.embed

  &--wrapper
    @apply bg-white
    &.embeds-iframe
      .embed--inner
        position: relative
        padding-top: 50%
        iframe
          position: absolute
          z-index: 10
          top: 0
          left: 0
          width: 100%
          height: 100%


  &--info
    // position: absolute
    // top: 50%
    // left: 50%
    // transform: translate(-50%, -50%)
    @apply p-4 max-w-md mx-auto


  &--footer
    padding: 0.5rem

  &--toggle
    @apply outline-none p-0 bg-none border-none flex gap-2
    @apply font-bold tracking-wide

    .icon--wrapper
      @apply text-green w-10 -mt-1
      svg
        path,
        circle
          @apply fill-current
