@font-face {
  font-family: "CooperHewitt";
  src: url("/assets/fonts/CooperHewitt-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CooperHewitt";
  src: url("/assets/fonts/CooperHewitt-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sifonn";
  src: url("/assets/fonts/Sifonn-Basic.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
